@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
  overflow: visible !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6 {
  @apply font-inter;
}
.rsuite-table th,
.rsuite-table td {
  font-family: 'Inter', sans-serif !important; /* Важно для переопределения стилей */
}

.rsuite-table {
  font-family: 'Inter', sans-serif !important;
}

.rs-progress-circle-info span {
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 3px !important;
}

.rs-picker-menu {
  will-change: transform;
  z-index: 9999 !important;
}

.rs-picker {
  position: relative !important;
  overflow: visible !important;
}
body[style*="padding-right"] {
  padding-right: 0px !important;
}